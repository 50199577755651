import { createRouter, createWebHistory } from '@ionic/vue-router'
import RedirectPage from '../views/RedirectPage.vue'
const cekLogin = async () => {
  let loggedIn = localStorage.getItem('chm_loggedIn')
  if (loggedIn === '0' || loggedIn === null) {
    return { path: '/login' }
  }
}

const routes = [
  //Public
  {
    path: '/',
    component: RedirectPage,
  },
  {
    path: '/non-member',
    component: () => import('../views/DashboardNonMember.vue'),
  },
  {
    path: '/impor-data',
    component: () => import('../views/ImporData.vue'),
    beforeEnter: cekLogin,
  },
  {
    path: '/member/booking-history/rincian/:id',
    component: () => import('@/views/RincianPage.vue'),
    beforeEnter: cekLogin,
  },
  {
    path: '/benefits/room-voucher',
    component: () => import('@/views/RoomVoucher.vue'),
    beforeEnter: cekLogin,
  },
  {
    path: '/benefits/room-voucher/use-voucher/:id_promo',
    component: () => import('@/views/RoomVoucherPakai.vue'),
    beforeEnter: cekLogin,
  },
  {
    path: '/benefits/resto-voucher',
    component: () => import('@/views/RestoVoucher.vue'),
    beforeEnter: cekLogin,
  },
  {
    path: '/benefits/resto-voucher/use-voucher/:id_promo',
    component: () => import('@/views/RestoVoucherPakai.vue'),
    beforeEnter: cekLogin,
  },
  {
    path: '/benefits/:tipe',
    component: () => import('@/views/PoolCoffee.vue'),
    beforeEnter: cekLogin,
  },

  {
    path: '/login',
    component: () => import('@/views/LoginPage.vue'),
  },
  {
    path: '/registrasi',
    component: () => import('@/views/RegistrasiPage.vue'),
  },
  {
    path: '/keranjang',
    component: () => import('@/views/ViewCart.vue'),
  },
  {
    path: '/info-pemesan',
    component: () => import('@/views/InfoPemesan.vue'),
  },
  {
    path: '/pembayaran',
    component: () => import('@/views/PembayaranPage.vue'),
  },
  {
    path: '/booking/:id',
    component: () => import('@/views/BookingPage.vue'),
  },
  {
    path: '/konfirmasi-booking',
    component: () => import('@/views/KonfirmasiBooking.vue'),
  },
  {
    path: '/konfirmasi-order',
    component: () => import('@/views/KonfirmasiOrder.vue'),
  },
  {
    path: '/pembayaran-sukses',
    component: () => import('@/views/PembayaranSuccess.vue'),
  },
  {
    path: '/pembayaran-pending',
    component: () => import('@/views/PembayaranPending.vue'),
  },
  {
    path: '/pembayaran-error',
    component: () => import('@/views/PembayaranError.vue'),
  },
  {
    path: '/profile-update',
    component: () => import('@/views/ProfileUpdate.vue'),
    beforeEnter: cekLogin,
  },
  {
    path: '/membership-cancellation',
    component: () => import('@/views/MembershipCancellation.vue'),
    beforeEnter: cekLogin,
  },
  {
    path: '/security-update',
    component: () => import('@/views/PasswordUpdate.vue'),
    beforeEnter: cekLogin,
  },
  {
    path: '/promo',
    component: () => import('@/views/PromoList.vue'),
  },
  {
    path: '/referrer/:id/:slot',
    component: () => import('@/views/ReferrerPage.vue'),
  },
  //Admin
  {
    path: '/admin/',
    component: () => import('../views/AdminPage.vue'),
    beforeEnter: cekLogin,
    children: [
      {
        path: 'dashboard',
        component: () => import('../views/AdmDashboard.vue'),
      },
      {
        path: 'member',
        component: () => import('../views/AdmMember.vue'),
      },
      {
        path: 'calon',
        component: () => import('../views/AdmCalon.vue'),
      },
      {
        path: 'transaksi',
        component: () => import('../views/AdmTransaksi.vue'),
      },
      {
        path: 'deleted-trx',
        component: () => import('../views/AdmTransaksiDeleted.vue'),
      },
      {
        path: 'promo',
        component: () => import('../views/AdmPromo.vue'),
      },
      {
        path: 'operator',
        component: () => import('../views/AdmOperator.vue'),
      },
      {
        path: 'operator/update',
        component: () => import('../views/AdmOperator-update.vue'),
      },
      {
        path: 'booking',
        component: () => import('../views/AdmBooking.vue'),
      },
      {
        path: 'promo/update',
        component: () => import('../views/AdmPromo-update.vue'),
      },
      {
        path: 'trx/gym-pool',
        component: () => import('../views/AdmGymPool.vue'),
      },
      {
        path: 'trx/free-coffee',
        component: () => import('../views/AdmFreeCoffee.vue'),
      },
      {
        path: 'trx/room',
        component: () => import('../views/AdmTrxRoom.vue'),
      },
      {
        path: 'trx/resto',
        component: () => import('../views/AdmTrxResto.vue'),
      },
      {
        path: 'rate',
        component: () => import('../views/AdmRate.vue'),
      },
      {
        path: 'setting',
        component: () => import('../views/SettingPage.vue'),
      },
      {
        path: 'cms/',
        component: () => import('../views/CmsPage.vue'),
        children: [
          {
            path: '',
            redirect: 'general',
          },
          {
            path: 'general',
            component: () => import('../views/CmsGeneral.vue'),
          },
          {
            path: 'pictures',
            component: () => import('../views/CmsPictures.vue'),
          },
          {
            path: 'about',
            component: () => import('../views/CmsAbout.vue'),
          },
          {
            path: 'promo',
            component: () => import('../views/CmsPromo.vue'),
          },
          {
            path: 'amenities',
            component: () => import('../views/CmsAmenities.vue'),
          },
        ],
      },
    ],
  },
  //Member
  {
    path: '/member/',
    component: () => import('../views/MemberPage.vue'),
    beforeEnter: cekLogin,
    children: [
      {
        path: '',
        redirect: '/member/dashboard',
      },
      {
        path: 'dashboard',
        component: () => import('@/views/DashboardPage.vue'),
      },
      {
        path: 'benefit',
        component: () => import('@/views/BenefitPage.vue'),
      },
      {
        path: 'booking-history',
        component: () => import('@/views/HistoriPage.vue'),
      },
      {
        path: 'qr-code',
        component: () => import('@/views/Qr-Code.vue'),
      },
      {
        path: 'profile',
        component: () => import('@/views/ProfilePage.vue'),
      },
    ],
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

export default router
