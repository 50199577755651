<script setup>
  //import { onIonViewWillEnter } from '@ionic/vue'
  import { inject, onMounted } from 'vue'
  import { useRouter } from 'vue-router'

  const store = inject('store')
  const key = localStorage.getItem('chm_session') || null

  const router = useRouter()
  onMounted(async () => {
    if (key !== null) {
      let q = await fetch(store.state.api_url + 'login?setRedirect', store.state.method)
      let r = await q.json()

      router.push(r[0])
    } else {
      router.push('/login')
    }
  })
</script>

<template>
  <div>Please wait while we are redirecting you the correct page...</div>
</template>
