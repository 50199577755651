<script setup>
  import { Capacitor } from '@capacitor/core'
  import { Device } from '@capacitor/device'
  import { StatusBar, Style } from '@capacitor/status-bar'
  import { ref, provide, onMounted } from 'vue'
  import { IonApp, IonRouterOutlet, alertController } from '@ionic/vue'
  import store from '@/store'

  provide('store', store)
  const w100 = ref(true)
  const isNative = ref(false)
  const nowPlatform = ref('')

  const logDeviceInfo = async () => {
    const deviceInfo = await Device.getId()
    localStorage.setItem('chm_device', deviceInfo.identifier)
  }

  const getIsNative = async () => {
    let token = store.state.key
    if (token !== null && parseInt(token.slice(-2)) >= 15) {
      w100.value = false
    }
    if (Capacitor.isNativePlatform()) {
      isNative.value = true

      try {
        await StatusBar.setStyle({ style: Style.Light })
        await StatusBar.setOverlaysWebView({ overlay: true })
      } catch (e) {
        console.log(e)
      }
    }
    localStorage.setItem('chm_native', isNative.value ? '1' : '0')
  }

  const getVersion = async platform => {
    let currVersion = parseFloat(store.state.version)

    let q = await fetch(store.state.api_url + 'public_misc?getVersion')
    let r = await q.json()

    if (r > currVersion) {
      const updateModal = async () => {
        const alert = await alertController.create({
          message: 'Silahkan update ke versi terbaru.',
          backdropDismiss: false,
          buttons: [
            {
              text: 'Update',
              role: 'confirm',
              handler: () => {
                if (platform === 'android') {
                  window.location.assign('https://play.google.com/store/apps/details?id=app.cambridgehotelmedan.com')
                } else {
                  window.location.assign('https://apps.apple.com/us/app/cambridge-membership/id1634554394')
                }
              },
            },
          ],
        })
        alert.present()
      }
      updateModal()
    }
  }

  onMounted(async () => {
    logDeviceInfo()
    getIsNative()
    if (isNative.value === true) {
      nowPlatform.value = Capacitor.getPlatform()
      getVersion(nowPlatform.value)
    }
  })
</script>
<template>
  <ion-app :class="w100 ? (isNative ? 'w100 native' : 'w100') : 'admin'">
    <ion-router-outlet style="background: #fff" />
    <loading-box />
  </ion-app>
</template>

<style scoped>
  .w100 {
    max-width: 100%;
    margin: 0 auto;
    background-color: #fff;
  }
  .native {
    --ion-safe-area-top: 25px;
  }
  .admin {
    --ion-safe-area-top: 0;
  }
</style>
